// COLORES DE LA APLICACIÓN AQUI PIDO USUARIO FINAL*

// Cherry Red
// #F70025

// Ice
// #F7EFE2

// Marmalade
// #F25C00

// Orange Juice
// #F9A603

$aqui-pido-red: #f70025;
$aqui-pido-ice: #f7efe2;
$aqui-pido-orange: #f25c00;
$aqui-pido-yellow: #f9a603;
$aqui-pido-text-color-base: #444444;

$aqui-pido-red-palette: (
    "base": #f70025,
    "lighten-5": #fee0e5,
    "lighten-4": #fdb3be,
    "lighten-3": #fb8092,
    "lighten-2": #f94d66,
    "lighten-1": #f82646,
    "darken-1": #f60021,
    "darken-2": #f5001b,
    "darken-3": #f30016,
    "darken-4": #f1000d,
);

$aqui-pido-red-mat-pallete: (
    50: #fee0e5,
    100: #fdb3be,
    200: #fb8092,
    300: #f94d66,
    400: #f82646,
    500: #f70025,
    600: #f60021,
    700: #f5001b,
    800: #f30016,
    900: #f1000d,
    A100: #ffffff,
    A200: #ffe5e5,
    A400: #ffb2b3,
    A700: #ff989a,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$aqui-pido-orange-mat-palette: (
    50: #fdebe0,
    100: #fbceb3,
    200: #f9ae80,
    300: #f68d4d,
    400: #f47426,
    500: #f25c00,
    600: #f05400,
    700: #ee4a00,
    800: #ec4100,
    900: #e83000,
    A100: #ffffff,
    A200: #ffe1dc,
    A400: #ffb5a9,
    A700: #ffa090,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$aqui-pido-yellow-mat-pallete: (
    50: #fef4e1,
    100: #fde4b3,
    200: #fcd381,
    300: #fbc14f,
    400: #fab329,
    500: #f9a603,
    600: #f89e03,
    700: #f79502,
    800: #f68b02,
    900: #f57b01,
    A100: #ffffff,
    A200: #fff2e8,
    A400: #ffd6b5,
    A700: #ffc89c,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #ffffff,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

$aqui-pido-text-color-base-pallete: (
    50: #e9e9e9,
    100: #c7c7c7,
    200: #a2a2a2,
    300: #7c7c7c,
    400: #606060,
    500: #444444,
    600: #3e3e3e,
    700: #353535,
    800: #2d2d2d,
    900: #1f1f1f,
    A100: #f07a7a,
    A200: #eb4c4c,
    A400: #ff0505,
    A700: #eb0000,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #ffffff,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

$font-family-base: Arimo, Helvetica, "sans-serif" !default;
$font-size-base: 1em;
$font-color: $aqui-pido-text-color-base;
$font-weight: 600;

// $breakpoint-sm: 600px;
// $breakpoint-md: 600px;
// $breakpoint-lg: 1360px;

// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;

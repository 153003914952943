/* You can add global styles to this file, and also import other style files */
@import "assets/css/variables.scss";
@import "assets/css/ahaar/style_aahar.scss";
@import "assets/scss/hover.scss";
@import "assets/css/custom-angular-mat-theme.scss";

html,
body {
    font-family: $font-family-base;
    font-size: $font-size-base;
    color: $font-color;
    font-weight: $font-weight;
    -webkit-overflow-scrolling: touch;
    text-size-adjust: none;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
}

// Angular Rounter Element
router-outlet {
    display: block;
}

.cursorPointer {
    cursor: pointer;
}

.centerAlign-all {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.centerAlign-horizontal {
    display: flex !important;
    justify-content: center;
}
.centerAlign-vertical {
    display: flex !important;
    align-items: center;
}

html,
body {
    overflow-y: hidden;
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

body {
    div.fullScreen {
        height: 100vh;
        width: 100vw;
        overflow-y: auto;
    }
}

.mat-autocomplete-panel {
    background-color: $aqui-pido-red !important;
}

.mat-autocomplete-panel .mat-option-text {
    color: $aqui-pido-ice !important;
}

.aqui-pido-btn {
    background: $aqui-pido-red none repeat scroll 0 0;
    color: #ffffff;
    display: block;
    font-size: 1.125em;
    font-weight: 700;
    height: 50px;
    letter-spacing: 2px;
    line-height: 50px;
    text-transform: uppercase;
    cursor: pointer;
    bottom: 0;
    position: absolute;
    width: 100%;
    border-radius: 4px;
}

.aqui-pido-btn:hover {
    background-color: #000;
}

.menu__grid__thumb > a > .product-img {
    height: 33vh !important;
    width: auto !important;
    border-radius: 8px;
    margin-bottom: 8px;
}

.textCapitalize {
    text-transform: capitalize !important;
}

.transparentDivider {
    height: 1px;
    overflow: hidden;
}

.fullWidth {
    width: 100%;
}

.fullHeight {
    height: 100%;
}

.textCenter {
    text-align: center;
}

.backgroundWhite {
    background-color: $aqui-pido-ice;
}

.extrasScrollBar {
    // height: 70px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.extrasScrollBar::-webkit-scrollbar {
    width: 8px;
}

.extrasScrollBar::-webkit-scrollbar-track {
    background: $aqui-pido-ice;
    border-radius: 12px;
}

.extrasScrollBar::-webkit-scrollbar-thumb {
    background: $aqui-pido-text-color-base;
    border-radius: 12px;
}

// .bodyScrollBar::-webkit-scrollbar {
//     width: auto;
// }

// .bodyScrollBar::-webkit-scrollbar-track {
//     background: $aqui-pido-text-color-base;
//     // border-radius: 12px;
// }

// .bodyScrollBar::-webkit-scrollbar-thumb {
//     background: $aqui-pido-red;
//     // border-radius: 12px;
// }

// .bodyScrollBar::-webkit-scrollbar-thumb:hover {
//     background: $aqui-pido-orange;
// }

.itemDisabled {
    pointer-events: none !important; //This makes it not clickable
    opacity: 0.6 !important;
}

.fabRightButton {
    position: fixed;
    background-color: $aqui-pido-text-color-base;
    color: $aqui-pido-ice;
    bottom: 2vh;
    right: 2vw;
    opacity: 0.9;
    text-align: center;
    border-radius: 3em;
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    i {
        font-size: 2rem !important;
        padding-top: 4px;
        padding-left: 4px;
        padding-right: 4px;
    }
}

.fabTopLeft {
    position: fixed;
    top: 2vh;
    left: 2vw;
    opacity: 0.9;
    text-align: center;
    border-radius: 3em;
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.fabTopRight {
    position: fixed;
    top: 2vh;
    right: 2vw;
    opacity: 0.9;
    text-align: center;
    border-radius: 3em;
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.show {
    display: none;
    visibility: visible;
}

.hide {
    display: none;
    visibility: visible;
}

input {
    margin-bottom: 0 !important;
}

@media (max-width: 600px) {
    .whatsapp-swal-wrapper {
        background-color: transparent !important;
        padding: 0;
        background-image: none !important;
        box-shadow: none !important;
        color: white !important;
    }

    .whatsapp-swal-wrapper :active {
        background-image: none !important;
        box-shadow: none !important;
        color: white !important;
    }

    .whatsapp-swal-wrapper :focus {
        background-image: none !important;
        box-shadow: none !important;
        color: white !important;
    }

    .whatsapp-swal-wrapper :hover {
        background-image: none !important;
        color: white !important;
    }

    .whatsapp-swal {
        padding: 16px 12px;
        background: $aqui-pido-red none repeat scroll 0 0;
        color: #ffffff;
        display: inline;
        font-size: 9px;
        font-weight: 700;
        letter-spacing: 2px;
        line-height: 50px;
        text-transform: uppercase;
        cursor: pointer;
        border-radius: 4px;
    }
}

@media (min-width: 601px) {
    .whatsapp-swal-wrapper {
        background-color: transparent !important;
        padding: 0;
        background-image: none !important;
        box-shadow: none !important;
        color: white !important;
    }

    .whatsapp-swal-wrapper :active {
        background-image: none !important;
        box-shadow: none !important;
        color: white !important;
    }

    .whatsapp-swal-wrapper :focus {
        background-image: none !important;
        box-shadow: none !important;
        color: white !important;
    }

    .whatsapp-swal-wrapper :hover {
        background-image: none !important;
        color: white !important;
    }

    .whatsapp-swal {
        padding: 16px 12px;
        background: $aqui-pido-red none repeat scroll 0 0;
        color: #ffffff;
        display: inline;
        font-size: 1.125em;
        font-weight: 700;
        letter-spacing: 2px;
        line-height: 50px;
        text-transform: uppercase;
        cursor: pointer;
        border-radius: 4px;
    }
}

.invisibleScroll::-webkit-scrollbar {
    width: 0;
}

.invisibleScroll::-webkit-scrollbar-track {
    background: none;
    // border-radius: 12px;
}

.invisibleScroll::-webkit-scrollbar-thumb {
    background: none;
    // border-radius: 12px;
}

.invisibleScroll::-webkit-scrollbar-thumb:hover {
    background: none;
}

.aqui-pide-bg-red {
    background-color: $aqui-pido-red;
    color: white;
}

.padding-16 {
    padding: 1em;
}

.pt-2em {
    padding-top: 2em;
}

.sidenav {
    z-index: 1000 !important;
}

.whatsappImg {
    width: 20px;
    height: 20px;
}

.not-user-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

input {
    border-bottom: none !important;
    outline: none !important;
    height: auto !important;
}

input:focus {
    border-bottom: none !important;
    box-shadow: none !important;
}

::ng-deep .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding-bottom: 0 !important;
}

.genericText {
    font-size: 1em !important;
    color: #444444 !important;
    font-weight: 600 !important;
}

.orange-text-color {
    color: $aqui-pido-orange !important;
}

.red-text-color {
    color: $aqui-pido-red !important;
}

.mat-radio-ripple,
.mat-checkbox-ripple,
.mat-slide-toggle-ripple {
    display: none !important;
}

button {
    &.mat-button-base,
    &.mat-calendar-body-cell {
        &:active,
        &:focus {
            background-color: transparent !important;
        }
    }
}

a {
    &.btn,
    &.btn-large {
        &:active,
        &:hover,
        &:focus {
            background-color: $aqui-pido-text-color-base !important;
        }
    }
}

.mat-slide-toggle-thumb-container {
    &::before {
        display: none !important;
    }
    .mat-slide-toggle-thumb {
        margin-left: -35px;
    }
}
